import { Box } from "@mui/material";
import Logo from "../../assets/logo/footer-web-logo.png";
import LogoMini from "../../assets/logo/hyalineLogo.png";
import { useTranslation } from "react-i18next";
import AndroidLogo from "../../assets/android.png";
import AppleLogo from "../../assets/apple.png";
import WindowsLogo from "../../assets/windows.png";
import { Title, Info, ItemRightBox, ItemLeftBox } from "./indexCss";
import { styled } from "@mui/material/styles";

const BoxContainer = styled(Box)(({ theme }) => ({
  maxWidth: 1312,
  margin: "0 auto",
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("lgUp")]: {
    padding: "24px clamp(1rem,4cqi,5.625rem)",
  },
  [theme.breakpoints.up("lgUp")]: {
    padding: "24px 0",
  },
}));

const BoxContainerMini = styled(Box)(() => ({
  width: '100%',
  display: "flex",
  flexDirection: 'column',
  justifyContent: "space-between",
  padding: "24px clamp(1rem,4cqi,5.625rem)",
}));

function Footer() {
  let { t } = useTranslation();
  const infos1 = [
    t("footer.info11"),
    t("footer.info12"),
    t("footer.info13"),
    t("footer.info14"),
    t("footer.info15"),
    t("footer.info16"),
  ];
  const infos2 = [t("footer.info21"), t("footer.info22")];
  const infos4 = [t("footer.info41"), t("footer.info42")];
  return (
    <>
      <Box
        sx={{
          bgcolor: "rgba(84, 84, 84, 1)",
          display: { xs: "none", md: "flex" },
        }}
      >
        <BoxContainer>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
              borderRight: "1px solid #fff",
            }}
          >
            <ItemLeftBox style={{ margin: "auto" }}>
              <img src={Logo} alt="" style={{ width: 76, height: 79 }} />
            </ItemLeftBox>
            <ItemLeftBox>
              <Title>{t("footer.title1")}</Title>
              {infos1.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </ItemLeftBox>
            <ItemLeftBox>
              <Title>{t("footer.title2")}</Title>
              {infos2.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </ItemLeftBox>
          </Box>
          <Box
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <ItemRightBox>
              <Title>{t("footer.title3")}</Title>
              <Box sx={{ display: "flex" }}>
                <img
                  src={AppleLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>IOS</Info>
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={AndroidLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>Android</Info>
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={WindowsLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>WIN</Info>
              </Box>
            </ItemRightBox>
            <ItemRightBox>
              <Title>{t("footer.title4")}</Title>
              {infos4.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </ItemRightBox>
          </Box>
        </BoxContainer>
      </Box>
      <Box
        sx={{
          bgcolor: "rgba(84, 84, 84, 1)",
          display: { xs: "flex", md: "none" },
        }}
      >
        <BoxContainerMini>
          <ItemLeftBox style={{ margin: "auto", marginBottom: 24 }}>
              <img src={LogoMini} alt="" style={{ width: 76, height: 79 }} />
            </ItemLeftBox>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #fff",
              padding: '0 32px'
            }}
          >
            <ItemLeftBox>
              <Title>{t("footer.title1")}</Title>
              {infos1.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </ItemLeftBox>
            <ItemLeftBox>
              <Title>{t("footer.title2")}</Title>
              {infos2.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </ItemLeftBox>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              borderBottom: "1px solid #fff",
              padding: '12px 32px'
            }}
          >
            <Box>
              <Title>{t("footer.title3")}</Title>
              <Box sx={{ display: "flex" }}>
                <img
                  src={AppleLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>IOS</Info>
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={AndroidLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>Android</Info>
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={WindowsLogo}
                  alt=""
                  style={{
                    width: "16px",
                    height: "16px",
                    marginRight: "8px",
                  }}
                />
                <Info>WIN</Info>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              borderBottom: "1px solid #fff",
              padding: '12px 32px'
            }}
          >
            <Box>
              <Title>{t("footer.title4")}</Title>
              {infos4.map((v) => {
                return <Info key={v}>{v}</Info>;
              })}
            </Box>
          </Box>
        </BoxContainerMini>
      </Box>
    </>
  );
}
export default Footer;
