import { datas } from "../../locales/user-agreement/data";
import { useLocation } from 'react-router-dom';
import language from "../../utils/language";
function Scene() {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('lang');
  console.log(query);
  let text = datas['en_GB'];
  let temp = {
    sysLanguageId: 7,
    sysLanguageCode: "en_GB",
    sysLanguageName: "English",
  }
  if (query) {
    const langs = language
    for (let i in langs) {
      if (langs[i].sysLanguageCode === query) {
        temp = langs[i]
        break;
      }
    }
  }
  let _t = temp.sysLanguageCode
  let lang = _t && datas.hasOwnProperty(_t) ? _t : "zh_CN";
  text = datas[lang];
  
  return (
    <div style={{
      maxWidth: '1280px',
      margin: '0px auto',
      padding: 20,
      color: 'rgba(33, 33, 33, 1)',
      fontSize: '11px',
      fontWeight: 400,
      lineHight: 1.6667
    }}>
      {text.map((item, index) => {
        return <div key={index} style={{textAlign: index == 0 ? 'center' : 'left'}}>{item}<p></p></div>
      })}
    </div>
  );
}
export default Scene;
